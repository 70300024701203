import "./Searcher.scss"

import bg from "../../assets/searcherBg.webp"
import { Link } from "react-router-dom"

export default function({step, setStep, updateSearchRequest}) {
  const nextStep = (value) => {
    updateSearchRequest("property", value)
    setStep(2)
  }
  
  return(<div className="Searcher">
    <img src={bg} className="s_bgImage" />
    <div className="s_bgOverlay" />

    <section className="s_body">
      <h1 className="s_title">Encuentra tu lugar perfecto</h1>

      <div className="s_container sc_1">
        <span onClick={() => nextStep("apartment")}>
          <i className="fas fa-building"></i>
          <h2>Departamentos</h2>
        </span>
        <span onClick={() => nextStep("home")}>
          <i className="fas fa-home"></i>
          <h2>Casas</h2>
        </span>
        <span onClick={() => nextStep("land")}>
          <i className="fas fa-map-pin"></i>
          <h2>Terrenos</h2>
        </span>
      </div>
    </section>
  </div>)
}