// react
import React, { useEffect, useState } from 'react';
import { Route, Routes, MemoryRouter } from 'react-router-dom';

// components
import Header from '../../components/Header/Header.jsx';

// pages
// import Properties from './pages/Properties/Properties';

import "./App.scss"
import Searcher from '../Searcher/Searcher.jsx';
import PropertiesList from '../PropertiesList/PropertiesList.jsx';

export default function() {
  const [searchRequest, setSearchRequest] = useState({
    property: "",
    minPrice: "",
    maxPrice: "",
    maxDistance: "",
    lat: "",
    lng: "",
  })

  const updateSearchRequest = (p1, v1, p2, v2, p3, v3, p4, v4) => {
    const copy = {...searchRequest};
    copy[p1] = v1;
    if(p2) {copy[p2] = v2}
    if(p3) {copy[p3] = v3}
    if(p4) {copy[p4] = v4}

    setSearchRequest(copy)
  }

  const reqProps = {searchRequest, setSearchRequest, updateSearchRequest}

  // useEffect(() => {
  //   console.log("searchREquest", searchRequest);
  // }, [searchRequest])

  
  return(<div className='AppLayout'>
    <Header />

    <main className='Main'>
      <MemoryRouter>
        <Routes>
          <Route
            path='/'
            element={<Searcher {...reqProps} />}
            // element={<PropertiesList {...reqProps} />}
          />
          <Route 
            path='/home'
            element={<PropertiesList {...reqProps} />}
          />
          <Route 
            path='/apartment'
            element={<PropertiesList {...reqProps} />}
          />
          <Route 
            path='/land'
            element={<PropertiesList {...reqProps} />}
          />
        </Routes>
      </MemoryRouter>        
    </main>
  </div>)}