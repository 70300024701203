// components
import { useState } from "react";
import CurrencyInput from "../CurrencyInput/CurrencyInput";
import GoogleSearchBox from "../GoogleSearchBox/GoogleSearchBox";

import "./SearchFilters.scss"

export default function({updateSearchRequest, setCurrentPage, setProperties}) {
  const [errorMessage, setErrorMessage] = useState(null)
  
  const handleSearch = () => {
    const minPrice = document.getElementById("minPrice").value;
    const maxPrice = document.getElementById("maxPrice").value;
    
    const cleanValue = value => {
      const val2 = value.replace(/[$,]/g, ''); //without $ and ,
      return parseFloat(val2);
    }
       
    
    const coordsValue = document.getElementById("coordsValue")?.innerHTML
    if(coordsValue) {
      setErrorMessage(null)
  
      const [lat, lng] = coordsValue.split(",")
      updateSearchRequest(
        "lat", lat, 
        "lng", lng, 
        "minPrice", cleanValue(minPrice) ? cleanValue(minPrice) : null, 
        "maxPrice", cleanValue(maxPrice) ? cleanValue(maxPrice) : null,
      )
    } else {
      setErrorMessage("Selecciona una ubicación e intenta de nuevo")
    }
    setCurrentPage(0)
    setProperties(null)
  }
  
  
  return(<div className="p_filters">
    <GoogleSearchBox key={"lñaksdf"}/>
    <span className="si_error">{errorMessage}</span>
    <CurrencyInput id="minPrice" key={"d2kddas"} placeholder="$ Mínimo" type="text" />
    <CurrencyInput id="maxPrice" key={"d2kd"} placeholder="$ Máximo" type="text" />

    <button className="f_btn" onClick={handleSearch}>Buscar</button>
  </div>)
}