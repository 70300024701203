import "./Searcher.scss"

import bg from "../../assets/searcherBg.webp"
import CurrencyInput from "../../components/CurrencyInput/CurrencyInput"
import { useNavigate } from "react-router-dom"

export default function({searchRequest, updateSearchRequest}) {
  const navigate = useNavigate()
  
  const handleSearch = () => {
    navigate(searchRequest?.property)
  }

  const handleInput = (value, id) => {
    const cleanValue = value.replace(/[$,]/g, ''); //without $ and ,
    const finalValue = parseFloat(cleanValue);

    updateSearchRequest(id, finalValue ? finalValue : "")
  }
  
  
  return(<div className="Searcher">
    <img src={bg} className="s_bgImage" />
    <div className="s_bgOverlay" />

    <section className="s_body">
      <h1 className="s_title">Nos ajustamos a cualquier presupuesto</h1>

      <div className="s_container sc_2">
        <span className="s_inputBox">
          <CurrencyInput placeholder="$ Mínimo (opcional)" type="text" onChange={e => handleInput(e.target.value, "minPrice")} />
        </span>
        <span className="s_inputBox">
          <CurrencyInput placeholder="$ Máximo (opcional)" type="text" onChange={e => handleInput(e.target.value, "maxPrice")} />
        </span>
        <span className="s_btnBox">
          <button className="s_btn" onClick={handleSearch}>Buscar</button>
        </span>
      </div>
    </section>
  </div>)
}