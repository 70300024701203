import calculateDistance from "./calculateDistance";

export default async function(
  setState, // response setter
  lat,
  lng,

  type="land", // home, apartment, land

  minPrice, // float number
  maxPrice, // float number
  
  maxDistance, // kilometers

  setMaxPages, // max number of pages for the paginator
  page=1, // lazy load number of page
  setCurrentPage,
  
  bedrooms='1', // number of bedrooms
) {
  let foundProperties = [];
  let maxPages = 0;
  let url;

  if(window.location.origin == "https://propiedades.asesoresclave.com") {
    url = `https://backend-propiedades-asesoresclave.fly.dev/api/properties/get`;
  } else {
    url = `http://localhost:3000/api/properties/get`;
  }

  

  await fetch(url, {
    method: 'POST',
    body: JSON.stringify({ type, lat, lng, minPrice, maxPrice, maxDistance, page }),
    headers: {
      'Content-Type': 'application/json',
    },
      mode: "cors"
  })
  .then(response => {
    if (response.ok) {
        return response.json();
      } else {
        throw new Error('Error al realizar la solicitud a la API');
      }
    })
    .then(data => {
      foundProperties = data.properties;
      maxPages = data.maxPages
    })
    .catch(error => {
      console.error(error);
    });
    
  setCurrentPage(page==0 ? 1 : page)
  setMaxPages(maxPages)
  setState(foundProperties ? foundProperties : [])
}