export default function ({}) {
  const { compose, withProps, lifecycle } = require("recompose");
  const {
    withScriptjs,
  } = require("react-google-maps");
  const { StandaloneSearchBox } = require("react-google-maps/lib/components/places/StandaloneSearchBox");

  const PlacesWithStandaloneSearchBox = compose(
    withProps({
      googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyBYn50Rc41eBSNVzZYbQ0iAWfbpHLFhjzE&v=3.exp&libraries=geometry,drawing,places",
      loadingElement: <div style={{ height: `100%` }} />,
      containerElement: <div style={{ height: `400px` }} />,
    }),
    lifecycle({
      componentWillMount() {
        const refs = {}

        this.setState({
          places: [],
          onSearchBoxMounted: ref => {
            refs.searchBox = ref;
          },
          onPlacesChanged: () => {
            const places = refs.searchBox.getPlaces();
            this.setState({
              places,
            });
          },
        })
      },
    }),
    withScriptjs  
  )(props => {

    return (<>
      <div className="SearchBox" data-standalone-searchbox="" key="SearchBox">
        <StandaloneSearchBox
          ref={props.onSearchBoxMounted}
          bounds={props.bounds}
          onPlacesChanged={props.onPlacesChanged}
        >
          <input
            name="location"
            className="form__field"
            id="location"
            type="text"
            placeholder="Ingresa la ubicación"
          />
        </StandaloneSearchBox>
      </div>
      <i id="coordsValue">
        {props.places[0] && <>
          {props.places[0]?.geometry?.location?.lat()},{props.places[0]?.geometry?.location?.lng()}
        </>}
      </i>
      </>
    )
  });

  return (<PlacesWithStandaloneSearchBox />)
}