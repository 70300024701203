import { useEffect, useState } from "react"
import { Link } from "react-router-dom"

import "./Searcher.scss"

import bg from "../../assets/searcherBg.webp"
import Searcher_1 from "./Searcher_1"
import Searcher_2 from "./Searcher_2"
import Searcher_3 from "./Searcher_3"

export default function({searchRequest, setSearchRequest, updateSearchRequest}) {
  const [step, setStep] = useState(1)
    
  const sProps = {step, setStep, searchRequest, setSearchRequest, updateSearchRequest}

  return(<div className="Searcher">
    <div className="mWrap">
      {
        step === 1 ? <Searcher_1 {...sProps} />
        : step === 2 ? <Searcher_2 {...sProps} />
        : step === 3 ? <Searcher_3 {...sProps} />
        : null
      }
    </div>
  </div>)
}