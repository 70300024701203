import { useEffect, useState } from "react"

import homeBg from "../../assets/homeBg.webp"
import landBg from "../../assets/landBg.webp"
import apartmentBg from "../../assets/apartmentBg.webp"
import SearchFilters from "../../components/SearchFilters/SearchFilters"

export default function({
  currentPage,
  maxPages,
  setProperties,
  setCurrentPage,
  properties,
  searchRequest,
  setSearchRequest,
  updateSearchRequest,
  empty,
}) {
  
  const handleChangePage = number => {
    setProperties(null)
    setCurrentPage(number)
  }

  const handleClickProperty = (property) => {
    window.location.href = `https://api.whatsapp.com/send?phone=523315344074&text=Buen%20d%C3%ADa%20me%20gustar%C3%ADa%20saber%20m%C3%A1s%20sobre%20%C3%A9sta%20propiedad%3A%20${encodeURIComponent(property.title)}.`
  }

  
  return (<>
    <div className="p_hero">
      <img className="ph_bg" src={
          searchRequest.property === "home" ? homeBg
          : searchRequest.property === "land" ? landBg
          : apartmentBg
        } 
        style={{filter: searchRequest.property === "land" && "blur(3px)"}}
      />
      
      <div className="ph_overlay" />
      <div className="ph_cta">
        <h1 className="ph_title">{
          searchRequest.property === "home" ? "Tu hogar, tu elección"
          : searchRequest.property === "land" ? "Descubre tu terreno ideal"
          : "Encuentra tu oasis urbano"
        }</h1>
      </div>
    </div>





    <div className="mWrap">
      <SearchFilters 
        updateSearchRequest={updateSearchRequest} 
        setCurrentPage={setCurrentPage}
        setProperties={setProperties}
      />
      
      {
        !empty ? <>
          <ul className="p_list">
            {properties.map(property => <li className="p_property">
              <img className="p_cover" src={"https://assets.easybroker.com/property_images/3921086/64533419/medium_EB-OB1086.jpg?version=1691010423"}/>
              
              <div className="p_info">
                <h2>{property.title}</h2>
                <p>{property.description}</p>
                <p>196 m²</p> {/* // to do details part */}
                <span><b>{property.price}</b> en venta</span>
              </div>

              <button className="p_btn" onClick={_=>handleClickProperty(property)}>Informes aquí</button>
            </li>)}
          </ul>




          <div className="p_paginator">
            {
              Array.from(Array(maxPages).keys()).map(number=>{
                const n = number+1 //bcs it is an index number: 0
                
                if(n === currentPage-1 ||
                  n === currentPage-2 ||
                  n === currentPage   ||
                  n === currentPage+1 ||
                  n === currentPage+2
                  ) {
                  return (
                  <button 
                    disabled={n === currentPage}
                    key={"btn"+n}
                    onClick={_=>handleChangePage(n)}
                  >
                    {n}
                  </button>
                  )
                } else {
                  return null
                }})
            }
          </div>
        </>





        : <div className="emptyMessageContainer">
          <h1 className="ph_subTitle">No hay propiedades disponibles ahora para tu búsqueda. Ajusta los filtros para cambiar los resultados.</h1>
        </div>
      }
      
    </div>
  </>)
}