import { useRef, useState } from "react"

import "./Searcher.scss"

import bg from "../../assets/searcherBg.webp"
import GoogleSearchBox from "../../components/GoogleSearchBox/GoogleSearchBox"

export default function({setStep, updateSearchRequest}) {
  const [errorMessage, setErrorMessage] = useState(null)


  const handleSearch = () => {
    const coordsValue = document.getElementById("coordsValue")?.innerHTML
    if(coordsValue) {
      setErrorMessage(null)

      const [lat, lng] = coordsValue.split(",")
      updateSearchRequest("lat", lat, "lng", lng)
      
      setStep(3)
    } else {
      setErrorMessage("Selecciona una ubicación e intenta de nuevo")
    }
  }

  
  return(<div className="Searcher">
    <img src={bg} className="s_bgImage" />
    <div className="s_bgOverlay" />

    <section className="s_body">
      <h1 className="s_title">¿Donde estás buscando?</h1>

      <div className="s_container sc_2">
        <span className="s_inputBox">
          <GoogleSearchBox />
          <span className="si_error">{errorMessage}</span>
        </span>
        <span className="s_btnBox">
          <button className="s_btn" onClick={handleSearch}>Siguiente</button>
        </span>
      </div>
    </section>
  </div>)
}