// react
import { useEffect, useState } from "react"

// services
import readProperties from "../../services/readProperties.js"

// components
import "./PropertiesList.scss"
import List from "./List.jsx"

//assets
import homeBg from "../../assets/homeBg.webp"
import landBg from "../../assets/landBg.webp"
import apartmentBg from "../../assets/apartmentBg.webp"
import loadingGif from "../../assets/loading.gif"
import GoogleSearchBox from "../../components/GoogleSearchBox/GoogleSearchBox.js"

export default function({
  coords,
  searchRequest,
  setSearchRequest,
  updateSearchRequest,
}) {
  const [properties, setProperties] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [maxPages, setMaxPages] = useState(null)
  const listProps = {
    currentPage,
    properties,
    setProperties,
    setCurrentPage,
    maxPages,
    searchRequest,
    setSearchRequest,
    updateSearchRequest,
  }
  

  useEffect(() => { //GET API
    const req = searchRequest;
    
    // console.log("Consuming API", properties);
    
    readProperties(
      setProperties,
      req.lat,
      req.lng,
      req.property, //home || apartment || land
      req.minPrice,
      req.maxPrice,
      1000, // req.maxDistance,
      setMaxPages,
      currentPage,
      setCurrentPage,
    )

    // console.log("searchRequest", searchRequest, "currentPage", currentPage);
    
    // readProperties(
    //   setProperties,
    //   "20.6914663",
    //   "-103.2982774",
    //   "home",
    //   "",
    //   "",
    //   1000, // req.maxDistance,
    //   setMaxPages,
    //   currentPage,
    // )
  }, [currentPage])

  // useEffect(() => {
  //   console.log("CurrentPage", currentPage);
  // })

  
  return(
    <div className="PropertiesListLayout">
      <img src={loadingGif} style={{display:"none"}} />
      <img className="ph_bg" 
        src={
          searchRequest.property === "home" ? homeBg
          : searchRequest.property === "land" ? landBg
          : apartmentBg}
        style={{display:"none"}}
      />

      {
        properties === null ?
        <div className="p_loading">
          <img src={loadingGif} />
        </div>
        
        : properties.length > 0 ?
        <List {...listProps} />
          
        : 
        <List {...listProps} empty />
      }
    </div>
  )
}